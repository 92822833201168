<!-- 这个页面待用吧 -->
<template>
<div>
    <div style="padding:10px; font-size:18px">AI诊断结论：{{ SyntheticalConclusion }}</div>
    <div style="padding:10px; font-size:18px">监测时间：{{ createTime }}</div>
    <div style="padding:10px; font-size:18px">监测时长：{{ formattedTime }}</div>
    <!-- <div style="margin-top:0px; width:600px; height:620px;">
      <div style="padding:10px; font-size: 20px;"><b>我的心电散点图</b></div>
      <div id="Scatter"
        style="width:550px; height:550px;margin: 10px;border:solid 3px #428bca;border-radius: 20px;">
      </div>
    </div> -->
    <div id="BroadcastBar" style="width:90%; height:400px;margin: 10px;">
    </div>
    
</div>
    
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
//import引入的组件需要注入到对象中才能使用

components: {},
data() {
//这里存放数据
return {
    broadcastContent: '',
    SyntheticalConclusion: '',
    did: '',
    data_rList: [],
    conclusionData:"",
    r_num: 0,
    createTime : "",
    formattedTime:""
};
},
//监听属性 类似于data概念
computed: {
    crossPlatformStatus: {
    get() { return this.$store.state.module.crossPlatform },
    set(val) { this.$store.commit('module/updateCrossPlatform', val) }
    },
},
//监控data中的数据变化
watch: {},
//方法集合
methods: {
  // 绘制散点图
    ScatterChart(domName) {
      this.Scatter = this.$echarts.init(domName);


      let option = {
        title: {
        },

        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
            dataZoom: { show: true },
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true },
            myTool: {
              show: true,
              title: "什么是散点图？",
              // icon:'path://M578.064516 792.774194H445.935484v-165.161291c0-95.033806 40.23329-142.864516 137.083871-208.532645l7.498322-5.086968C658.861419 367.616 677.16129 345.154065 677.16129 297.290323A165.16129 165.16129 0 0 0 346.83871 297.290323H214.709677a297.290323 297.290323 0 1 1 594.580646 0c0 104.216774-43.272258 157.266581-144.582194 226.006709l-7.531355 5.12C593.754839 571.458065 578.064516 590.088258 578.064516 627.612903v165.161291z m-66.064516 231.225806a82.580645 82.580645 0 1 1 0-165.16129 82.580645 82.580645 0 0 1 0 165.16129z',
              icon: 'path://M554.666667 874.666667a21.333333 21.333333 0 1 1-21.333334-21.333334 21.333333 21.333333 0 0 1 21.333334 21.333334z m0-149.333334V637.84a74.9 74.9 0 0 1 23.44-54.32c18.306667-17.26 36.286667-33.04 53.673333-48.306667 36-31.6 70-61.446667 94.566667-94.38C754.766667 402.74 768 364.34 768 320a234.666667 234.666667 0 1 0-469.333333 0 21.333333 21.333333 0 0 0 42.666666 0c0-105.866667 86.133333-192 192-192s192 86.133333 192 192c0 76.32-51.046667 121.126667-121.7 183.146667C586 518.666667 567.7 534.666667 548.84 552.466667A117.76 117.76 0 0 0 512 637.84V725.333333a21.333333 21.333333 0 0 0 42.666667 0z',
              onclick: () => {
                this.dialogVisible3 = true
                // console.log(this.dialogVisible3)
              }

            }
          },
          top: 5,
          right: "8%",
        },
        legend: {
          top: 100,
          itemWidth: 5,
          itemGap: 15,
          textStyle: {
            fontSize: 20
          }
        },
        grid: {
          top: 70,

          right: 50,
        },
        xAxis: [
          {
            type: 'value',
            scale: true,
            name: 'RRn(ms)',
            axisLine: {
              lineStyle: {
                color: 'black',
                width: 2,
              },
            },
            axisLabel: {
              formatter: '{value} ',
              fontSize: 16,
              inside: true
            },
            min: 0,//parseInt((min_rList-error_rList/2)/100)*100,
            max: 2000,//parseInt((max_rList+error_rList/2)/100+1)*100,
            splitLine: {
              show: false,
            },
            splitNumber: 4,
            nameLocation: 'center',
            nameTextStyle: {
              fontSize: 20,
              padding: 0
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            scale: true,
            name: 'RRn+1(ms)',
            axisLine: {
              lineStyle: {
                color: 'black',
                width: 2,
              },
            },
            axisLabel: {
              formatter: '{value} ',
              fontSize: 16,
              inside: true
            },
            min: 0,//parseInt((min_rList-error_rList/2)/100)*100,
            max: 2000,//parseInt((max_rList+error_rList/2)/100+1)*100,
            splitLine: {
              show: false,
            },
            splitNumber: 4,
            nameLocation: 'center',
            nameTextStyle: {
              fontSize: 20,
              padding: 0
            }
          }
        ],
        series: [
          {
            name: '检测结果',
            type: 'scatter',
            symbolSize: 2, //设定实心点的大小
            itemStyle: { normal: { color: 'black', lineStyle: { color: 'red' } } },
            data: this.data_rList//pot_list
          },
          {
            // name: '辅助线1',
            type: 'line',
            data: [[0, 0], [2000, 2000]],
            itemStyle: {
              normal: {
                color: 'gray', // 颜色置灰
                width: 1, // 宽度1就行了
                lineStyle: {
                  type: 'dotted' // line样式为虚线
                }
              }
            }

          }, {
            // name: '辅助线2',
            type: 'line',
            data: [[500, 0], [0, 500]],
            itemStyle: {
              normal: {
                color: 'gray', // 颜色置灰
                width: 1, // 宽度1就行了
                lineStyle: {
                  type: 'dotted' // line样式为虚线
                }

              }
            }
          }, {
            // name: '辅助线3',
            type: 'line',
            data: [[1000, 0], [0, 1000]],
            itemStyle: {
              normal: {
                color: 'gray', // 颜色置灰
                width: 1, // 宽度1就行了
                lineStyle: {
                  type: 'dotted' // line样式为虚线
                }
              }
            }
          },
          {
            // name: '辅助线4',
            type: 'line',
            data: [[1500, 0], [0, 1500]],
            itemStyle: {
              normal: {
                color: 'gray', // 颜色置灰
                width: 1, // 宽度1就行了
                lineStyle: {
                  type: 'dotted' // line样式为虚线
                }
              }
            }
          },
          {
            // name: '辅助线5',
            type: 'line',
            data: [[2000, 0], [0, 2000]],
            itemStyle: {
              normal: {
                color: 'gray', // 颜色置灰
                width: 1, // 宽度1就行了
                lineStyle: {
                  type: 'dotted' // line样式为虚线
                }
              }
            }
          },
        ]
      };
      this.Scatter.setOption(option);
      // window.addEventListener("resize", () => {  //根据屏幕进行实时绘制
      // myChart.resize();
      // });
    },
    // 初始化播报记录图表
    initChart(domName, chosenResult,labelName) {
      this.chart = this.$echarts.init(domName);
      this.BroadcastBar_option = {
        title: {
          text: '综合结论异常统计',
          x: 'center',
        },
        xAxis: {
          type: 'category',
          data: labelName,
          name: '疾病名称',
          axisLabel:{
            rotate: 60
          }
        },
        yAxis: {
          type: 'value',
          name:'异常计数'
        },
        series: [
          {
            data: chosenResult,
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            }
          }
        ]
      }
      this.chart.setOption(this.BroadcastBar_option);

    },
    handleBroadcast() {
      this.$http.adornHeader(this.header)
      // 根据每条心电记录的did获取该条的结论
      this.$http({
        url: this.$http.adornUrl('/personal/dynamicecg/getConclusion'),
        method: 'get',
        params: this.$http.adornParams({ "did": this.did })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log(data.conclusionData)
          this.broadcastContent = data.conclusionData
          // 获取到结论后，判断是新还是老的存储形式,新的就处理一下
          if (this.broadcastContent[this.broadcastContent.length - 1] == ',') {
            this.broadcastContent = "[" + this.broadcastContent.substring(0, this.broadcastContent.length - 1) + "]";
          }
          this.broadcastContent = JSON.parse(this.broadcastContent)
          var qtycmap = { 0: "ST-T正常", 1: "T波倒置", 2: "ST段抬高", 3: "ST段压低" }//心梗相关疾病的map
          for (var i = 0; i < this.broadcastContent.length; i++) {
            this.broadcastContent[i].qtyc_map = qtycmap[this.broadcastContent[i].qtyc]
          }

          // 统计播报记录非零疾病的数量
          var countDisease = {};
          // 遍历数组
          this.broadcastContent.forEach(obj => {
            // 获取对象的键
            Object.keys(obj)
              // 对每个键执行reduce函数操作，计算非零项数量
              .forEach(key => {
                countDisease[key] = (countDisease[key] || 0) + (obj[key] !== 0);
              });
          });

          var chosenKeys = ["xdgs", "xdgh", "xlbq", "dxtb", "fxzb", "jjxzb", "sxzb", "ssxxdgs", "sxxdgs", "fxyb", 'sxyb', 'fc', 'qtyc']
          this.labelName= ['窦性心动过速', '窦性心动过缓', '窦性心律不齐', '窦性停搏', '房性早搏', '交界性早搏', '室性早搏', '室上性心动过速', '室性心动过速', '交界性逸博', '室性逸博', '房颤', '心梗相关疾病']
          var chosenResult = {};
          chosenKeys.forEach(key => {
            chosenResult[key] = countDisease[key];
          });
          console.log(chosenResult)
          this.CountData = Object.values(chosenResult)  // 每一项疾病非零项的计数
          this.initChart(document.getElementById("BroadcastBar"), this.CountData, this.labelName);
          
          // this.handleScatter(data)

        } else {
          this.$message.error(data.msg)
        }
      })
    },
    getSyntheticalConclusion() {
      console.log("sblzy", this.did);
      this.$http.adornHeader(this.header)
      this.$http({
        url: this.$http.adornUrl('/personal/dynamicecg/getFinalConclusion'),
        method: 'post',
        params: this.$http.adornParams({ did: this.did })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看sblzy返回啥", data);
          this.SyntheticalConclusion = data.final_conclusion
        } else {
          // this.getCaptcha()
          this.SyntheticalConclusion = data.final_conclusion
        }
      })
    },
    // handleScatter(data){
    //   this.conclusionData = data.conclusionData
    //       // 获取到结论后，判断是新还是老的存储形式,新的就处理一下
    //       if (this.conclusionData[this.conclusionData.length - 1] == ',') {
    //         this.conclusionData = "[" + this.conclusionData.substring(0, this.conclusionData.length - 1) + "]";
    //       }

    //       // 将20s一条的结论转换为对象数组，然后提取出所有记录的createTime
    //       this.conclusionData = JSON.parse(this.conclusionData)

    //       for (let i = 0; i < this.conclusionData.length; i++) {

    //         // 计算散点图数据 （有误差算法）
    //         let r_count = this.rlist[i].length
    //         for (let r_ind = 1; r_ind < r_count - 2; r_ind++) {
    //           let sca_coor = new Array(2)
    //           sca_coor[0] = (this.rlist[i][r_ind] - this.rlist[i][r_ind - 1]) * 1000 / 500
    //           sca_coor[1] = (this.rlist[i][r_ind + 1] - this.rlist[i][r_ind]) * 1000 / 500
    //           this.r_num += 1
    //           this.data_rList.push(sca_coor)
    //         }
    //       }
    //       // 获取到所有的createTime后，取前9个20秒(3分钟)作为初始数据 
    //       this.ScatterChart(document.getElementById("Scatter"))
    // }
},
//生命周期 - 创建完成（可以访问当前this实例）
created() {
    
    if (this.$route.query.crossPlatform != null && this.$route.query.crossPlatform != undefined) {
        // 小程序端请求
        this.crossPlatformStatus = true
    }
    if (this.$store.state.module.crossPlatform) {
        this.header = { "Cross-Platform-Verification": "Cross-Platform-Access" }
    }
    // this.did='500'
    if (this.$route.query.did != null && this.$route.query.did != undefined) {
        // this.uid = this.$route.query.uid
        this.did = this.$route.query.did
        this.formattedTime = this.$route.query.formattedTime
        this.createTime = this.$route.query.createTime

    }
},
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {
   this.getSyntheticalConclusion()
   this.handleBroadcast()
},
beforeCreate() {}, //生命周期 - 创建之前
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>

</style>